<template>
  <AutheticationGuard>
    <div>
      <Navbar />
      <Loader v-if="loader" />
      <div v-if="!loader">
        <main class="dev-main" style="background-color: white;display:none">
          <div class="container">
            <div class="top_div"></div>
            <div class="client_type">
              <div class="product_owner" @click="handleClientFlow">
                <div class="box_title">I AM A FOUNDER</div>
                <div class="box_info">
                 I'm looking for tech experts to build my Product.
                </div>
                <div class="icon_div">
                  <img src="/img/onboarding/idea.svg" />
                </div>
              </div>
              <div class="vertical_divider"></div>
              <div class="product_team" @click="handleDevFlow">
                <div class="box_title">I AM AN EXPERT</div>
                <div class="box_info">
                  I'm looking for exciting missions to contribute my technical expertise
                </div>
                <div class="icon_div">
                  <img src="/img/onboarding/work.svg" />
                </div>
              </div>
            </div>
            <!-- <div class="login_div" @click="handleLogin">
              Have an account already?
              <span>Login</span>
            </div> -->
          </div>
        </main>
      </div>
    </div>
  </AutheticationGuard>
</template>

<script>
import AutheticationGuard from "@/components/auth/AuthGuard";
import Navbar from "@/components/Navbar/onboarding_navbar";
import Loader from "@/components/general/centerLoader";
import { mapActions, mapState } from "vuex";
import { changeUserType } from "@/api/team";
export default {
  components: {
    AutheticationGuard,
    Navbar,
    Loader,
  },

  data: () => ({
    emailAddress: "",
    prevRoute: null,
    loader: false,
  }),
  beforeRouteEnter(to, from, next) {
    // console.log((from))
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
   
    this.saveUserReferralCode();
    
  },
  methods: {
    //   ...mapActions("devOnboarding", ["updateUserEmail"]),
    ...mapActions("userManagement", [
      "resetUserLogin",
      "setAutheticatedUser",
      "setUserReferralCode",
      "setCustomerType",
    ]),
    saveUserReferralCode() {
      if (this.$route.query.ref) {
        let code = this.$route.query.ref;
        this.setUserReferralCode(code);
      }

       //alert('hahah')
    },
    handleClientFlow() {
      let query;
      //console.log(this.$route.query)
      if (this.$route.query.ref) {
        query = this.$route.query.ref;
      }

      //console.log("11111");
      if (this.currentUser && this.currentUser.email) {
        //console.log("222222");
        // console.log(this.currentUser)
        this.updateUserType(2);
      } else {
        //console.log("33333");
        this.setCustomerType(2);
        this.$router.push({ name: "sign_up", query: { ref: query } });
      }
    },
    handleDevFlow() {
      let query;
      if (this.$route.query.ref) {
        query = this.$route.query.ref;
      }
      //alert(query);
      if (this.currentUser && this.currentUser.email) {
        this.updateUserType(1);
      } else {
        this.setCustomerType(1);
        this.$router.push({ name: "sign_up", query: { ref: query } });
      }
    },
    async updateUserType(type) {
      this.loader = true;
      // alert(type)
      await changeUserType(type)
        .then((response) => {
         
          //console.log(response)
          if (response.data.status == 1) {
            const { user, nylas } = response.data.data;
            //console.log(this.$router)

           // console.log("User Type", nylas);
            this.updateLoginUser(user);
            if (type == 1) {
              this.$router.push({ name: "linkedin_profile" });
            } else {
               
              this.$router.push({ name: "start_project" });
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loader = false;
        });
    },
    handleCancel() {
      window.location.href = `${process.env.VUE_APP_SPLASHPAGE}`;
    },
    handleGoBack() {
      if (this.prevRoute.name == "login") {
        this.$router.push({ name: "login" });
      } else {
        window.location.href = `${process.env.VUE_APP_SPLASHPAGE}`;
      }
    },
    clearLocalStorage() {
      this.resetUserLogin();
      localStorage.removeItem("grupa_user");
    },
    handleLogin() {
      this.$router.push({ name: "login" });
    },
    updateLoginUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      this.setAutheticatedUser(payload);
    },
  },
  created() {
     if (localStorage.getItem("grupa_user") === null) {
      //console.log("user not logged In");
      this.handleLogin()
    } 
    // this.clearLocalStorage();
    // let userParam=atob(this.$route.params.userEmail);
    // if(userParam.includes("@")){
    //   this.emailAddress=atob(this.$route.params.userEmail);
    //   this.updateUserEmail(this.emailAddress)
    // }
    // else{
    // this.emailAddress='';
    // this.updateUserEmail(this.emailAddress)
    // }
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.top_div {
  display: flex;
}
.div_cancel {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 50%;
}
.go_back {
  width: 50%;
  justify-content: left;
  display: flex;
}
.client_type {
  margin-top: 85px;
  display: flex;
  justify-content: center;
}
.product_owner {
  border: 0.5px solid #e4e5e7;
  margin-top: 35px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 302px;
  padding: 24px;
  height: 178px;
  margin-right: 66px;
  cursor: pointer;
}
.product_team {
  margin-top: 35px;
  background: #ffffff;
  border: 0.5px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  width: 307px;
  padding: 24px;
  height: 178px;
  margin-left: 66px;
  cursor: pointer;
}
.box_title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: initial;
  line-height: 130%;
  color: #1b1e22;
}
.box_info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #c8cbd0;
  margin-top: 16px;
  text-align: initial;
}
.vertical_divider {
  border-left: 1px solid #cdcdcd;
  height: 248px;
}
.icon_div {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.go_back_span {
  color: #0055a5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 5px;
}
.login_div {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  margin-top: 48px;
}
.login_div span {
  color: #0055a5;
  cursor: pointer;
}
@media (max-width: 768px) {
  .client_type {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vertical_divider {
    display: none;
  }
  .product_team {
    margin-left: 0px;
  }
  .product_owner {
    margin-right: 0px;
  }
}
</style>