var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AutheticationGuard", [
    _c(
      "div",
      [
        _c("Navbar"),
        _vm.loader ? _c("Loader") : _vm._e(),
        !_vm.loader
          ? _c("div", [
              _c(
                "main",
                {
                  staticClass: "dev-main",
                  staticStyle: { "background-color": "white", display: "none" },
                },
                [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "top_div" }),
                    _c("div", { staticClass: "client_type" }, [
                      _c(
                        "div",
                        {
                          staticClass: "product_owner",
                          on: { click: _vm.handleClientFlow },
                        },
                        [
                          _c("div", { staticClass: "box_title" }, [
                            _vm._v("I AM A FOUNDER"),
                          ]),
                          _c("div", { staticClass: "box_info" }, [
                            _vm._v(
                              "\n               I'm looking for tech experts to build my Product.\n              "
                            ),
                          ]),
                          _c("div", { staticClass: "icon_div" }, [
                            _c("img", {
                              attrs: { src: "/img/onboarding/idea.svg" },
                            }),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "vertical_divider" }),
                      _c(
                        "div",
                        {
                          staticClass: "product_team",
                          on: { click: _vm.handleDevFlow },
                        },
                        [
                          _c("div", { staticClass: "box_title" }, [
                            _vm._v("I AM AN EXPERT"),
                          ]),
                          _c("div", { staticClass: "box_info" }, [
                            _vm._v(
                              "\n                I'm looking for exciting missions to contribute my technical expertise\n              "
                            ),
                          ]),
                          _c("div", { staticClass: "icon_div" }, [
                            _c("img", {
                              attrs: { src: "/img/onboarding/work.svg" },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }